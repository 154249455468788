import React from 'react';
import logo from './logo.png';
import './App.css';
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { COGNITO } from './config/aws'
import '@aws-amplify/ui-react/styles.css'

Amplify.configure(COGNITO)


function App() {
  return (
      <div className='App'>
        <header className='App-header'>
            <img src={logo} className='App-logo' alt='logo' />
    <Authenticator signUpAttributes={["email"]}>
          {({ signOut, user }) => 
            <>
            {user &&
              <p>
                Hello {user.username}, it's Morbin Time.
              </p>
            }
            <button onClick={signOut}>Sign out</button>
            </>
          }
    </Authenticator>
          </header>
          </div>
  );
}

export default App;
